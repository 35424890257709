/* See: https://fullcalendar.io/docs/css-customization */
:root {
  --fc-button-active-bg-color: theme("colors.rcdr-green-dark");
  --fc-button-active-border-color: theme("colors.rcdr-green-dark");
  --fc-button-bg-color: theme("colors.rcdr-green");
  --fc-button-border-color: theme("colors.rcdr-green-dark");
  --fc-button-hover-bg-color: theme("colors.rcdr-green-dark");
  --fc-button-hover-border-color: theme("colors.rcdr-green-dark");
}

@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: theme("width.2");
}

::-webkit-scrollbar-track {
  background-color: theme("backgroundColor.gray.400");
}

::-webkit-scrollbar-thumb {
  background-color: theme("backgroundColor.rcdr-green");
}

::-webkit-scrollbar-thumb:hover {
  background-color: theme("backgroundColor.rcdr-green-dark");
}

.fc-toolbar-title::first-letter {
  text-transform: uppercase;
}

.fc-daygrid-day-number {
  flex-grow: 1;
  overflow: hidden;
  text-align: right;
}
